import { environment } from '@accredible-frontend-v2/envs';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as DirectoryStore from './directory/directory.reducer';
import { directoryFeatureKey } from './directory/directory.reducer';

export interface State {
  [directoryFeatureKey]: DirectoryStore.DirectoryState;
}

export const reducers: ActionReducerMap<State> = {
  [directoryFeatureKey]: DirectoryStore.reducer,
};

export const metaReducers: MetaReducer<State>[] = environment.type !== 'production' ? [] : [];
