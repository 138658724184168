import { SearchableGroup } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpotlightApiService } from '../../../shared/services/sl-api/sl-api.service';
import { SpotlightDirectory } from '../../models/spotlight-directory.model';

export enum DirectoryUrl {
  LOAD_DIRECTORY_BY_DOMAIN = '/v1/credential-net/spotlight_directories/by_domain?domain=',
  LOAD_DIRECTORY_BASE = '/v1/credential-net/spotlight_directories',
}

enum DirectoryPayload {
  LOAD_DIRECTORY = 'spotlight_directory',
  LOAD_DIRECTORY_SKILLS = 'skills',
  LOAD_DIRECTORY_GROUPS = 'groups',
}

@Injectable({ providedIn: 'root' })
export class DirectoryApiService extends SpotlightApiService {
  // Mock example - this._get(`../assets/mock-json/directory.json`, true)
  loadDirectory(domain: string, propagate404: boolean): Observable<SpotlightDirectory> {
    return this._get(`${DirectoryUrl.LOAD_DIRECTORY_BY_DOMAIN}${domain}`).pipe(
      map((res) => this._handleResponse(res, DirectoryPayload.LOAD_DIRECTORY)),
      catchError((res) => this._handleError(res, propagate404)),
    );
  }

  // Mock example - this._get(`../assets/mock-json/skills.json`, true)
  loadDirectorySkills(directoryId: number): Observable<string[]> {
    return this._get(`${DirectoryUrl.LOAD_DIRECTORY_BASE}/${directoryId}/skills`).pipe(
      map((res) => this._handleResponse(res, DirectoryPayload.LOAD_DIRECTORY_SKILLS)),
      catchError((res) => this._handleError(res)),
    );
  }

  // Mock example - this._get(`../assets/mock-json/eligible_groups.json`, true)
  loadDirectoryGroups(
    directoryId: number,
    organizationIds?: number[],
  ): Observable<SearchableGroup[]> {
    let queryParam = '';
    if (Array.isArray(organizationIds) && organizationIds.length > 0) {
      queryParam = `?organization_ids[]=${organizationIds
        .map((id) => id)
        .join('&organization_ids[]=')}`;
    }

    return this._get(
      `${DirectoryUrl.LOAD_DIRECTORY_BASE}/${directoryId}/eligible_groups${queryParam}`,
    ).pipe(
      map((res) => this._handleResponse(res, DirectoryPayload.LOAD_DIRECTORY_GROUPS)),
      catchError((res) => this._handleError(res)),
    );
  }
}
