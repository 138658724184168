import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export enum SpotlightRoutes {
  PROFILE = 'profile',
  NOT_FOUND = '404',
}

const routes: Routes = [
  // Can access regardless of authentication status
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('../containers/profile-list/profile-list.container.module').then(
        (m) => m.ProfileListContainerModule,
      ),
  },
  {
    path: SpotlightRoutes.PROFILE,
    loadChildren: () =>
      import('../containers/profile/profile.container.module').then(
        (m) => m.ProfileContainerModule,
      ),
  },

  // Invalid Routes
  {
    path: SpotlightRoutes.NOT_FOUND,
    loadChildren: () =>
      import('../containers/sl-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('../containers/sl-page-not-found/page-not-found.container.module').then(
        (m) => m.PageNotFoundContainerModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class SpotlightRoutingModule {}
