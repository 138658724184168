import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { SpotlightDirectory } from '../../models/spotlight-directory.model';
import * as DirectoryActions from './directory.actions';

export const directoryFeatureKey = 'directory';

export interface DirectoryState {
  directory: SpotlightDirectory;
  groupsFilterEnabledInSettings: boolean;

  action: DirectoryStateAction;
  payload: any;
  error: any;
}

export enum DirectoryStateAction {
  NO_ACTION,
  DIRECTORY_LOADED,
  ELIGIBLE_GROUPS_LOADED,
  HAS_ERROR,
}

const initialStateHandling = <DirectoryState>{
  action: DirectoryStateAction.NO_ACTION,
  payload: null,
  error: null,
};

export const initialState: DirectoryState = {
  directory: null,
  ...initialStateHandling,
};

export const reducer = createReducer(
  initialState,

  on(DirectoryActions.loadDirectory, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(DirectoryActions.loadDirectorySuccess, (state, { directory }) => ({
    ...state,
    directory: hideDirectoryFiltersWithNoOptions(directory),
    groupsFilterEnabledInSettings: directory.filters.groups,
    action: DirectoryStateAction.DIRECTORY_LOADED,
  })),
  on(DirectoryActions.loadDirectoryFailure, (state) => ({
    ...state,
    action: DirectoryStateAction.HAS_ERROR,
  })),
  on(DirectoryActions.loadDirectoryEligibleGroups, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(DirectoryActions.loadDirectoryEligibleGroupsSuccess, (state, { groups }) => ({
    ...state,
    directory: {
      ...state.directory,
      searchable_groups: groups,
      filters:
        groups.length > 0
          ? { ...state.directory.filters, groups: state.groupsFilterEnabledInSettings }
          : { ...state.directory.filters, groups: false },
    },
    action: DirectoryStateAction.ELIGIBLE_GROUPS_LOADED,
  })),
  on(DirectoryActions.loadDirectoryEligibleGroupsFailure, (state) => ({
    ...state,
    action: DirectoryStateAction.HAS_ERROR,
  })),
);

const hideDirectoryFiltersWithNoOptions = (directory: SpotlightDirectory): SpotlightDirectory => {
  const newDirectory = cloneDeep(directory);
  if (directory.searchable_groups.length < 1) {
    // Disable groups filter if there are no groups to filter by
    newDirectory.filters.groups = false;
  }
  if (directory.skills.length < 1) {
    // Disable skills filter if there are no skills to filter by
    newDirectory.filters.skills = false;
  }
  return newDirectory;
};
