import {
  AccredibleObjectWithId,
  EligibilitySource,
  EligibilityType,
  SearchableGroup,
} from '@accredible-frontend-v2/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { directoryFeatureKey, DirectoryState } from './directory.reducer';

export const selectDirectoryState = createFeatureSelector<DirectoryState>(directoryFeatureKey);

export const selectDirectoryStateAction = createSelector(
  selectDirectoryState,
  (state) => state.action,
);

export const selectDirectory = createSelector(selectDirectoryState, (state) => state.directory);

export const selectDirectoryId = createSelector(
  selectDirectoryState,
  (state) => state.directory.id,
);

export const selectDirectoryGroups = createSelector(
  selectDirectoryState,
  (state) => <SearchableGroup[]>state.directory.searchable_groups,
);

export const selectDirectorySkills = createSelector(selectDirectoryState, (state) => {
  return state.directory.skills.map((skill) => ({ name: skill, id: skill }));
});

export const selectDirectoryOrganizations = createSelector(selectDirectoryState, (state) => {
  const organizations: AccredibleObjectWithId[] = [];
  state.directory.eligibility_sources.forEach((source: EligibilitySource) => {
    const organization = source.type === EligibilityType.GROUP ? source.organization : source;
    if (!organizations.find((org) => org.id === organization.id)) {
      organizations.push(organization);
    }
  });

  return organizations;
});
