import { AccredibleAPIMockData, AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpotlightApiService extends AccredibleApiService {
  protected _handleError(
    res: HttpErrorResponse,
    propagate404 = false,
    mockData?: AccredibleAPIMockData,
  ): Observable<any> {
    switch (res.status) {
      case 400:
        return super._handleError(res, propagate404, mockData);

      case 403:
        // We want to handle a forbidden error as a 404 for the user
        // to give the impression that the page doesn't exist if the user isn't logged in and the owner
        const newRes = { ...res, status: 404 };
        return super._handleError(newRes, propagate404, mockData);

      case 404:
        return super._handleError(res, propagate404, mockData);
    }
  }
}
