import { SearchableGroup } from '@accredible-frontend-v2/models';
import { createAction, props } from '@ngrx/store';
import { SpotlightDirectory } from '../../models/spotlight-directory.model';

export const LOAD_DIRECTORY = '[Directory] Load Directory';
export const LOAD_DIRECTORY_SUCCESS = '[Directory] Load Directory Success';
export const LOAD_DIRECTORY_FAILURE = '[Directory] Load Directory Failure';
export const LOAD_DIRECTORY_ELIGIBLE_GROUPS = '[Directory] Load Directory Eligible Groups';
export const LOAD_DIRECTORY_ELIGIBLE_GROUPS_SUCCESS =
  '[Directory] Load Directory Eligible Groups Success';
export const LOAD_DIRECTORY_ELIGIBLE_GROUPS_FAILURE =
  '[Directory] Load Directory Eligible Groups Failure';

export const loadDirectory = createAction(
  LOAD_DIRECTORY,
  props<{ domain: string; propagate404: boolean; organizationIds: number[] }>(),
);
export const loadDirectorySuccess = createAction(
  LOAD_DIRECTORY_SUCCESS,
  props<{ directory: SpotlightDirectory }>(),
);

export const loadDirectoryFailure = createAction(LOAD_DIRECTORY_FAILURE, props<{ error: any }>());

export const loadDirectoryEligibleGroups = createAction(
  LOAD_DIRECTORY_ELIGIBLE_GROUPS,
  props<{ directoryId: number; organizationIds: number[] }>(),
);

export const loadDirectoryEligibleGroupsSuccess = createAction(
  LOAD_DIRECTORY_ELIGIBLE_GROUPS_SUCCESS,
  props<{ groups: SearchableGroup[] }>(),
);

export const loadDirectoryEligibleGroupsFailure = createAction(
  LOAD_DIRECTORY_ELIGIBLE_GROUPS_FAILURE,
  props<{ error: any }>(),
);
